import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import useWebSocket from "react-use-websocket";
import { useAppDispatch } from "../../app/store";
import Messager from "../../Components/Messager/Messager";
import ReactMarkdown from "react-markdown";
import ScrollToBottom from "react-scroll-to-bottom";
import { UpCarpet } from "../../icons";
import {
  getCharacters,
  getPrefixedCookieName,
  userEmail,
  userName,
} from "../../utils/utils";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { okaidia } from "react-syntax-highlighter/dist/esm/styles/prism";

import styles from "../../assets/css/markdown.module.css";
import rehypeRaw from "rehype-raw";

import useAgentMessageHandler from "../../hooks/useAgentMessageHandler";
import AgentSingleMsg from "./AgentSingleMsg";
import WelcomeScreen from "../../Components/WelcomeComponenet/WelcomeScreen";
import Cookies from "js-cookie";
import { basicSocketUrl } from "../../constants/constants";

const name = userName();
const token = Cookies.get(getPrefixedCookieName("crave_jwt_access_token"));
const email = userEmail();

const AgentMessageWrapper = ({
  menuOpen,
  showWelcome,
  setShowWelcome,
  myParam,
  data,
  error,
  isFetching,
  newChat,
  setNewChat,
}) => {
  const selectedConversation = useSelector(
    (state) => state.selectedConversation
  );

  const prevSelectedConversation = useSelector(
    (state) => state.prevSelectedConversation
  );

  const newCoversation = useSelector((state) => state.newCoversation);

  const currentTopic = data?.conversations?.filter((item) => {
    return item._id === selectedConversation;
  });

  let socketMessages = useSelector((state) => state.messages);

  const [connectionStatus, setConnectionStatus] = useState("Connecting");

  const [text, setText] = useState();
  const [prompt, setPromt] = useState();

  const [socketConversationStarted, setSocketConversationStarted] =
    useState(false);

  const [msgCounter, setMsgCounter] = useState(0);

  const msgCounterRef = useRef(msgCounter);

  const [questionId, setQuestionId] = useState();

  const questionIdRef = useRef(questionId);

  const selectedFilters = useSelector((state) => state.filters.selectedFilters);

  const [webSocketUrl, setWebSocketUrl] = useState();

  const { sendMessage, lastMessage, readyState, getWebSocket } = useWebSocket(
    webSocketUrl,
    {
      onOpen: () => {
        setConnectionStatus("Connected");
      },
      onError: (error) => {
        setConnectionStatus("Error");
        console.log("🚀 ~ onError: ~ error:", error);

        // Handle the error appropriately (e.g., display error message to user)
      },
      shouldReconnect: () => true,
      reconnectAttempts: 20,
      reconnectInterval: 3000,
    }
  );

  const messagesEndRef = useRef(null);

  const dispatch = useDispatch();
  const appDispatch = useAppDispatch();

  socketMessages = socketMessages.filter(
    (message) => message.conversation_id === selectedConversation
  );

  const sendSocketMessage = (message) => {
    sendMessage(message.prompt);
  };

  useEffect(() => {
    setWebSocketUrl(
      `${basicSocketUrl}rest/v3/gpt/agent/${myParam}?user=${email}&token=${token}${
        selectedConversation ? `&conversation_id=${selectedConversation}` : ""
      }`
    );
    if (
      prevSelectedConversation &&
      prevSelectedConversation !== "nofetch" &&
      selectedConversation &&
      selectedConversation !== "nofetch"
    ) {
      setSocketConversationStarted(false);
      setMsgCounter(0);
      setPromt();
    }
    if (
      prevSelectedConversation &&
      prevSelectedConversation !== "nofetch" &&
      selectedConversation &&
      selectedConversation === "nofetch"
    ) {
      setSocketConversationStarted(false);
      setMsgCounter(0);
      setPromt();
    }
  }, [prevSelectedConversation, selectedConversation, selectedFilters]);

  useEffect(() => {
    questionIdRef.current = questionId;
  }, [questionId]);

  useEffect(() => {
    msgCounterRef.current = msgCounter;
  }, [msgCounter]);

  useAgentMessageHandler(
    lastMessage,
    selectedConversation,
    myParam,
    appDispatch,
    setMsgCounter,
    setSocketConversationStarted,
    setPromt,
    msgCounterRef,
    questionIdRef,
    newCoversation
  );

  const handleSubmit = (e) => {
    e.preventDefault();
    localStorage.setItem("currentPrompt", text);
    if (text) {
      setPromt(text);
      setSocketConversationStarted(true);
    }
    sendMessage(text);
    setText("");
  };

  const pairChatMessages = (chatHistory) => {
    if (chatHistory === undefined || chatHistory === null) {
      return [];
    }
    const pairedMessages = [];
    let currentPrompt = null;

    chatHistory.forEach((message) => {
      if (message.content !== "TERMINATE") {
        if (!message.initial_prompt) {
          currentPrompt = message.content;
        } else if (message.name === "agent" && message.initial_prompt) {
          pairedMessages.push({
            prompt: currentPrompt,
            response: message.content,
            ...message,
          });
          currentPrompt = null; // Reset for the next pair
        }
      }
    });

    return pairedMessages;
  };

  const handleRegenerate = () => {
    // createMsgInfo.reset();
    // createMsg(formBody);
  };

  return (
    <main
      className={`w-full lg:w-3/4 fixed left-0 bottom-0  z-1 flex flex-col overflow-auto  transform ease-in-out duration-300 custom-height lg:ml-[24.5%] top-[91px]
      ${window.mapp ? "top-0" : ""} `}
    >
      <nav className="side-menu-nav flex-grow flex flex-col overflow-auto scroll-smooth ">
        {(showWelcome && !prompt) ||
        (newChat && newChat?.length > 0 && !selectedConversation && !prompt) ? (
          <WelcomeScreen myParam={myParam} />
        ) : (
          <ScrollToBottom className="flex relative left flex-col overflow-auto">
            <div className="border-0 mx-6 mr-10  mt-3 justify-center ">
              {pairChatMessages(
                currentTopic && currentTopic?.[0]?.chat_history
              )?.map(
                (
                  { conversation_id, response, prompt, feedback, yum_id },
                  index
                ) => (
                  <span key={index}>
                    <div className="tyn-qa-item flex gap-4 w-full">
                      <div className="tyn-qa-avatar align-middle">
                        <div className="tyn-media tyn-size-md">
                          <div className="relative inline-flex items-center justify-center w-8 h-8 overflow-hidden rounded-full bg-socket-light object-contain">
                            <span className="font-medium text-primary-white">
                              {name}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="self-center bg-socket-dark text-primary-white font-normal text-sm w-full rounded-lg flex justify-between p-[10px]">
                        <p className="text-base font-medium">{prompt}</p>
                        <span className="float-right self-center">
                          <UpCarpet width="28" height="28" />
                        </span>
                      </div>
                    </div>
                    <div className="tyn-qa-item flex gap-4 w-full mt-3">
                      <div className="tyn-qa-avatar">
                        <div className="tyn-qa-avatar-wrap">
                          <div className="tyn-media tyn-size-md">
                            <div className="tyn-media tyn-size-md">
                              <div
                                className={`relative inline-flex items-center justify-center w-8 h-8 overflow-hidden rounded-full ${
                                  myParam
                                    ? "bg-socket-dark"
                                    : "bg-primary-black"
                                } object-contain`}
                              >
                                <span className="font-medium text-primary-white">
                                  {getCharacters(name)}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="ans-block self-center text-primary-black font-normal p-2 text-sm w-full group relative">
                        <div className="text-base gap-2">
                          <ReactMarkdown
                            children={response}
                            className={styles.markdown}
                            rehypePlugins={[rehypeRaw]}
                            components={{
                              code({
                                node,
                                inline,
                                className,
                                children,
                                ...props
                              }) {
                                const match = /language-(\w+)/.exec(
                                  className || ""
                                );
                                return !inline && match ? (
                                  <SyntaxHighlighter
                                    children={String(children).replace(
                                      /\n$/,
                                      ""
                                    )}
                                    style={okaidia}
                                    language={match[1]}
                                    PreTag="div"
                                    {...props}
                                  />
                                ) : (
                                  <code className={className} {...props}>
                                    {children}
                                  </code>
                                );
                              },
                            }}
                          />
                          {/* )} */}
                        </div>
                      </div>
                    </div>
                  </span>
                )
              )}

              {prompt && (
                <AgentSingleMsg
                  prompt={prompt}
                  isLoading={socketConversationStarted}
                  requestBased={"socket"}
                />
              )}
            </div>
          </ScrollToBottom>
        )}
      </nav>

      <footer
        className="side-menu-footer flex-shrink-0 bg-skyblue  text-white py-5  border-primary-grey mx-auto"
        style={{ width: menuOpen ? "calc(100% - 25vw)" : "85%" }}
      >
        {/* <SidebarBtns /> */}
        <form action="" onSubmit={(e) => handleSubmit(e)}>
          <Messager
            text={text}
            setText={setText}
            handleSubmit={handleSubmit}
            myParam={myParam}
            socketConversationStarted={socketConversationStarted}
          />
        </form>
      </footer>
    </main>
  );
};

export default AgentMessageWrapper;
