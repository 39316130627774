import Cookies from "js-cookie";
// import Swal from "sweetalert2";
// import withReactContent from "sweetalert2-react-content";
// import { filter } from "smart-array-filter";

// export const MySwal = withReactContent(Swal);

// export const isEmpty = (val) => {
//   let newVal = val ? val.trim() : val;
//   if (
//     (typeof newVal !== "undefined" && newVal !== null && newVal.length === 0) ||
//     newVal === " "
//   ) {
//     return true;
//   }
//   return false;
// };

// export const isEmail = (val) => {
//   let validRegex =
//     /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
//   if (val.match(validRegex)) {
//     return true;
//   }
//   return false;
// };

// export const getSelectValues = (select) => {
//   let result = [];
//   let options = select && select.options;
//   let opt;

//   for (let i = 0, iLen = options.length; i < iLen; i++) {
//     opt = options[i];

//     if (opt.selected) {
//       result.push(opt.value || opt.text);
//     }
//   }
//   return result;
// };

// export const humanize = (str) => {
//   let i,
//     frags = str.split("_");
//   for (i = 0; i < frags.length; i++) {
//     frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
//   }
//   return frags.join(" ");
// };

// export const isCronValid = (freq) => {
//   let cronregex = new RegExp(
//     /^(\*|(\d|1\d|2\d|3\d|4\d|5\d)|\*\/(\d|1\d|2\d|3\d|4\d|5\d)) (\*|(\d|1\d|2[0-3])|\*\/(\d|1\d|2[0-3])) (\*|([1-9]|1\d|2\d|3[0-1])|\*\/([1-9]|1\d|2\d|3[0-1])) (\*|([1-9]|1[0-2])|\*\/([1-9]|1[0-2])) (\*|([0-6])|\*\/([0-6]))$/
//   );
//   return cronregex.test(freq);
// };

// export const getSVGURI = ({ prefix, iconName, icon }, color) =>
//   `data:image/svg+xml;base64,${btoa(
//     `<svg data-prefix="${prefix}" data-icon="${iconName}"
//       xmlns="http://www.w3.org/2000/svg" viewBox="0 0 ${icon[0]} ${icon[1]}">
//       <path fill="${color || "currentColor"}" d="${icon[4]}"></path>
//     </svg>`
//   )}`;

// export const getTodayDate = () => {
//   let now = new Date();
//   let today = now;
//   let dd = String(today.getDate()).padStart(2, "0");
//   let mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
//   let yyyy = today.getFullYear();
//   today = yyyy + "-" + mm + "-" + dd;
//   return today;
// };

// export const convert = (str) => {
//   if (str) {
//     let date = new Date(str),
//       month = ("0" + (date.getMonth() + 1)).slice(-2),
//       day = ("0" + date.getDate()).slice(-2);
//     return [date.getFullYear(), month, day].join("-");
//   }
//   return null;
// };

// export const filterByVal = (array, value) => {
//   return array.filter(
//     (data) =>
//       JSON.stringify(data).toLowerCase().indexOf(value.toLowerCase()) !== -1
//   );
// };

// export const defaultRegion = (array, value) => {
//   let access = Cookies.get("access");
//   if (access) {
//     access = access.replaceAll("\\", "");
//     access = access.replaceAll("054", ",");
//     access = JSON.parse(access);
//     const region = JSON.parse(localStorage.getItem("defaultRegion"));
//     if (region && access["dfp-admin"].write.includes("region")) {
//       return region;
//     } else {
//       localStorage.setItem(
//         "defaultRegion",
//         JSON.stringify(access["dfp-admin"].region[0])
//       );
//       return access["dfp-admin"].region[0];
//     }
//   }
//   return {};
// };

// export const userAccess = (fullAccess = false) => {
//   let access = Cookies.get("access");
//   if (access) {
//     access = access.replaceAll("\\", "");
//     access = access.replaceAll("054", ",");
//     access = JSON.parse(access);
//     return access["dfp-admin"];
//   }
//   return {};
// };

// export const userFullAccess = (fullAccess = false) => {
//   let access = Cookies.get("access");
//   if (access) {
//     access = access.replaceAll("\\", "");
//     access = access.replaceAll("054", ",");
//     access = JSON.parse(access);
//     return access;
//   }
//   return {};
// };

// export const checkVal = (obj, val, checkExec = false) => {
//   if (obj.read.includes(val)) return true;
//   else if (obj.write.includes(val)) return true;
//   else if (checkExec && obj.execute.includes(val)) return true;
//   return false;
// };

// export const checkWriteAccess = (obj, val) => {
//   return obj.write.includes(val);
// };

// export const checkExecAccess = (obj, val) => {
//   return obj.execute.includes(val);
// };

// export const defaultFilters = () => {
//   const oneWeekFromNow = new Date();
//   oneWeekFromNow.setDate(oneWeekFromNow.getDate() - 90);
//   const dashboardDate = new Date();
//   const supplyDmdDate = new Date();
//   const configSearchText = "";
//   const storeSearchText = "";
//   const userSearchText = "";
//   const ordersFilters = {
//     orderDate: [oneWeekFromNow, new Date()],
//     delDate: [null, null],
//     distributorName: [],
//   };
//   const orderSearchTxt = "";
//   const lineItemsTxt = "";
//   const notificationTxt = "";
//   const activeConfig = true;
//   const inventorySearchText = "";
//   const distributorSearchText = "";
//   const supplyDistributorSearchText = "";

//   const inventoryDate = [oneWeekFromNow, new Date()];

//   return {
//     dashboardDate,
//     supplyDmdDate,
//     configSearchText,
//     storeSearchText,
//     userSearchText,
//     inventorySearchText,
//     ordersFilters,
//     orderSearchTxt,
//     lineItemsTxt,
//     notificationTxt,
//     activeConfig,
//     inventoryDate,
//     distributorSearchText,
//     supplyDistributorSearchText,
//   };
// };

// export const addSearchParams = (text) => {
//   let refresh =
//     window.location.protocol +
//     "//" +
//     window.location.host +
//     window.location.pathname +
//     "?searchText=" +
//     text;
//   window.history.replaceState({ path: refresh }, "", refresh);
// };

// export const addConfigParams = (text, activeConfig) => {};

// export const addParams = (
//   text,
//   activeConfig,
//   delDate,
//   orderDate,
//   distName,
//   dateRange
// ) => {
//   let refresh = window.location.origin + window.location.pathname;

//   console.log(window, "Ref");
//   let url = new URL(refresh);
//   let params = new URLSearchParams(url.search);

//   if (text && !isEmpty(text)) {
//     if (refresh.match(/\?./)) {
//       params.set("searchText", text);

//       refresh =
//         window.location.protocol +
//         "//" +
//         window.location.host +
//         window.location.pathname +
//         "?" +
//         params.toString();
//     } else refresh += "?searchText=" + text;
//   } else {
//     if (refresh.match(/\?./)) {
//       params.delete("searchText");
//       refresh =
//         window.location.protocol +
//         "//" +
//         window.location.host +
//         window.location.pathname +
//         "?" +
//         params.toString();
//     }
//   }

//   if (delDate) {
//     if (refresh.match(/\?./)) {
//       refresh += "&delDate=" + delDate;
//     } else refresh += "?delDate=" + delDate;
//   }

//   if (orderDate) {
//     if (refresh.match(/\?./)) {
//       refresh += "&orderDate=" + orderDate;
//     } else refresh += "?orderDate=" + orderDate;
//   }
//   if (dateRange) {
//     if (refresh.match(/\?./)) {
//       refresh += "&dateRange=" + dateRange;
//     } else refresh += "?dateRange=" + dateRange;
//   }
//   if (distName) {
//     if (refresh.match(/\?./)) {
//       refresh += "&distName=" + distName;
//     } else refresh += "?distName=" + distName;
//   }

//   if (
//     activeConfig === true ||
//     activeConfig === "true" ||
//     activeConfig === false ||
//     activeConfig === "false"
//   ) {
//     if (refresh.match(/\?./)) {
//       refresh += "&activeconfig=" + activeConfig;
//     } else refresh += "?activeconfig=" + activeConfig;
//   }

//   console.log(refresh, "refresh");
//   window.history.replaceState({ path: refresh }, "", refresh);
// };

// export const convertArrValToString = (data) => {
//   const result = data.map((obj) =>
//     Object.fromEntries(
//       Object.entries(obj).map(([key, val]) => [key, String(val)])
//     )
//   );
//   return result;
// };

// export const getFilteredResults = (data, searchText, includePaths) => {
//   const res = filter(data, {
//     keywords: searchText,
//     includePaths,
//     excludedPaths: [],
//     caseSensitive: false,
//   });
//   return res;
// };

// export const filterObject = (input) => {
//   const filtered = {};
//   for (const key in input) {
//     if (input[key] !== undefined && input[key] !== null)
//       filtered[key] = input[key];
//   }
//   return filtered;
// };

// export function removeQueryParams(url) {
//   const urlParts = url.split("?");
//   return urlParts[0];
// }

// export const addQueryParams = (params, url = "") => {
//   if (params && Object.keys(params).length > 0) {
//     let queryString = Object.entries(params)
//       .map(([key, value]) => `${key}=${value}`)
//       .join("&");
//     return `${url}?${queryString}`;
//   }
// };

export function clearCookies() {
  var cookies = document.cookie.split(";");
  console.log(cookies, "cookies");
  for (var i = 0; i < cookies.length; i++) {
    var cookie = cookies[i];

    var eqPos = cookie.indexOf("=");
    var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
    document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/";
  }
}

export const userAccess = (fullAccess = false) => {
  let access = Cookies.get("access");
  if (access) {
    try {
      access = access.replaceAll("\\", "");
      access = access.replaceAll("054", ",");
      access = JSON.parse(access);
      if (access && access.helios) {
        return access.helios;
      }
      // Return a default value here if access.helios is not present
      return {};
    } catch (error) {
      console.error("Error parsing access data:", error);
    }
  }
  // Return a default value if access cookie is not present or cannot be parsed
  return {};
};

export const userEmail = () =>
  Cookies.get(getPrefixedCookieName("crave_email"));

export const userName = () => {
  const email = Cookies.get(getPrefixedCookieName("crave_email"));
  if (email) {
    const dotIndex = email.indexOf(".");

    // Get the first character and convert it to uppercase
    let firstCharacter = email.charAt(0).toUpperCase();

    // If dot exists and there are characters after it, get the character after dot
    if (dotIndex !== -1 && email.length > dotIndex + 1) {
      const characterAfterDot = email.charAt(dotIndex + 1).toUpperCase();
      return firstCharacter + characterAfterDot;
    }

    // If no dot, return only the first character
    return firstCharacter;
  }
};

export function getCharacters(str) {
  // Find the position of the underscore
  if (str) {
    const underscoreIndex = str.indexOf("_");

    // Get the first character and convert it to uppercase
    let mergedCharacters = str.charAt(0).toUpperCase();

    // If underscore exists and there are characters after it, capitalize the character after underscore
    if (underscoreIndex !== -1 && str.length > underscoreIndex + 1) {
      const characterAfterUnderscore = str
        .charAt(underscoreIndex + 1)
        .toUpperCase();
      mergedCharacters += characterAfterUnderscore;
    }

    return mergedCharacters;
  }
}

export function getEmailCharacters(email) {
  // Find the position of the dot
  const dotIndex = email.indexOf(".");

  // Get the first character and convert it to uppercase
  let firstCharacter = email.charAt(0).toUpperCase();

  // If dot exists and there are characters after it, get the character after dot
  if (dotIndex !== -1 && email.length > dotIndex + 1) {
    const characterAfterDot = email.charAt(dotIndex + 1).toUpperCase();
    return firstCharacter + characterAfterDot;
  }

  // If no dot, return only the first character
  return firstCharacter;
}

export function generateMongoLikeId() {
  const timestamp = ((new Date().getTime() / 1000) | 0).toString(16);
  const machineId = Math.floor(Math.random() * 16777215 + 1).toString(16);
  const processId = Math.floor(Math.random() * 16777215 + 1).toString(16);
  const counter = Math.floor(Math.random() * 15 + 1).toString(16);

  return timestamp + machineId + processId + counter;
}

function generateRandomHex(length) {
  let result = "";
  const characters = "0123456789abcdef";

  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    result += characters[randomIndex];
  }

  return result;
}

export function generateCustomId() {
  const timestamp = Date.now().toString(16); // Current timestamp in hexadecimal
  const randomPart = Math.random().toString(16).substr(2, 8); // Random part to ensure uniqueness
  const dateString = new Date().toISOString().replace(/[-:.TZ]/g, ""); // Current date in YYYYMMDDHHMMSS format
  return `${timestamp}-${randomPart}-${dateString}`;
}

export function getPrefixedCookieName(cookieName) {
  const currentDomain = document.domain;
  let prefix = "";
  if (currentDomain === "localhost") {
    prefix = "localhost_";
  } else if (currentDomain.endsWith("re-api.nonprod.yumds.com")) {
    prefix = "nonprod_";
  } else if (currentDomain.endsWith("aibot.yumds.com")) {
    prefix = "prod_";
  }

  const cookiesList = [
    "crave_email",
    "crave_access_token",
    "crave_jwt_access_token",
  ];

  return prefix && cookiesList.includes(cookieName)
    ? `${prefix}${cookieName}`
    : cookieName;
}

export function renameCookiesWithDomain() {
  const cookies = document.cookie.split(";");
  const domain = document.domain;

  let prefix = "";
  let hostname = "localhost";
  if (domain === "localhost") {
    prefix = "localhost_";
  } else if (domain.endsWith("re-api.nonprod.yumds.com")) {
    prefix = "nonprod_";
    hostname = "re-api.nonprod.yumds.com";
  } else if (domain.endsWith("aibot.yumds.com")) {
    prefix = "prod_";
    hostname = "aibot.yumds.com";
  }

  cookies.forEach((cookie) => {
    const [rawName, ...rest] = cookie.split("=");
    const name = rawName.trim();
    const cookiesList = [
      "crave_email",
      "crave_access_token",
      "crave_jwt_access_token",
    ];
    if (cookiesList.includes(name)) {
      const value = rest.join("=").trim();

      if (!name) return;
      if (prefix && name.startsWith(prefix)) return;
      if (!prefix) return;

      const newName = `${prefix}${name}`;
      document.cookie = `${newName}=${value};path=/;expires=Fri, 31 Dec 9999 23:59:59 GMT;`;
      document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=${hostname}`;
    }
  });
}
